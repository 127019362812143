import React from "react";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllDocs, getAllDocsSnap } from "../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { totalDeductions, totalGrossPay } from "../../utils/PayrollUtils";

function Compensation() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();
  const [payrollRuns, setPayrollRuns] = useState([]);
  const [selectedPayrollRun, setSelectedPayrollRun] = useState({});
  const [payPeriods, setPayPeriods] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [employees, setEmployees] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    const fetchPayrollRuns = async () => {
      await getAllDocsSnap("payrollRuns", (snap) => {
        const approvedRuns = snap.filter((run) => run.status === "approved");
        setPayrollRuns(approvedRuns);
      });
    };
    fetchPayrollRuns();
  }, []);

  useEffect(() => {
    const fetchEmployeeId = async () => {
      const data = await getAllDocs("employees");

      //sort by surname
      data.sort((a, b) => {
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.lastName) {
          return 1;
        }
        return 0;
      });

      const filteredEmployees = data.filter(
        (employee) => employee.status !== "Resigned"
      );

      setEmployees(filteredEmployees);

      if (currentUser.roles.includes("reviewer")) {
        setEmployeeId(filteredEmployees[0].id);
        setEmployeeInfo(filteredEmployees[0]);
      } else {
        const user = filteredEmployees.find(
          (employee) => employee.email === currentUser.email
        );
        setEmployeeId(user.id);
        setEmployeeInfo(user);
      }
    };
    fetchEmployeeId();
  }, []);

  const converter = (data) => {
    const result = parseFloat(data).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return result;
  };

  const employeeListOptions = employees.map((employee) => {
    return (
      <option value={employee.id}>
        {employee.lastName} {employee.firstName}
      </option>
    );
  });

  const handleSelectEmployeeId = (e) => {
    setEmployeeId(e.target.value);
    const userId = employees.find((employee) => employee.id === e.target.value);
    setEmployeeInfo(userId);
  };

  const handleSelectDate = (e) => {
    setSelectedDate(e.target.value);
  };

  const payrollRun = payrollRuns.find((run) => run.date === selectedDate);

  const paySlipData = payrollRun?.payregister.find(
    (item) => item.id === employeeId
  );

  const grossPay = totalGrossPay(paySlipData);
  const deductions = totalDeductions(paySlipData);
  const netPay = grossPay - deductions;

  return (
    <div className="text-left px-4">
      <div className="text-orange-600 font-semibold mb-4">Payslips</div>

      <div>
        <div className="font-bold text-leechiuBlue">Select Date</div>
        <input
          type="date"
          onChange={handleSelectDate}
          className="p-2 rounded-lg my-4"
        />
      </div>
      <div className="flex flex-col justify-start">
        {currentUser.roles.includes("reviewer") ? (
          <select
            className="border rounded-xl px-4 py-2 w-72 mb-8 "
            onChange={handleSelectEmployeeId}
          >
            {employeeListOptions}
          </select>
        ) : null}
      </div>

      {paySlipData ? (
        <div
          className="bg-gray-100 p-4 rounded xl shadow-lg m-2 text-left flex flex-col gap-4 text-xs"
          id="payslip"
        >
          <div className="font-semibold text-sm">
            <h4>Employee Name: {employeeInfo.displayName}</h4>
            <div>Department: {employeeInfo.department}</div>
            <div>Position: {employeeInfo.position}</div>
            <div>Payroll for the period: {selectedDate}</div>
          </div>
          <div className="flex lg:flex-row flex-col items-start gap-10">
            <div>
              <h4 className="font-bold">Gross Pay</h4>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <tbody>
                  {Object.entries({
                    "Basic Salary": converter(paySlipData?.basicSalary),
                    "Half Month": converter(paySlipData?.halfMonth),
                    "13th Month Pay (Non Taxable)": converter(
                      paySlipData?.thirteenMonthPay || 0
                    ),

                    "13th Month Pay (Taxable)": converter(
                      paySlipData?.thirteenMonthPayTaxable || 0
                    ),
                    Commissions: converter(paySlipData?.commission),
                    Incentives: converter(paySlipData?.incentives),
                    Overtime: converter(paySlipData?.overtime),
                    "Salary Adjustment": converter(
                      paySlipData?.salaryAdjustment
                    ),
                    "Tax Refund": converter(paySlipData?.taxRefund || 0),
                    "Leave without pay": converter(paySlipData?.lwop),
                    "Reimbursement /Cash Advances": converter(
                      paySlipData?.reimbursement || 0
                    ),
                    "Leave Conversion": converter(
                      paySlipData?.leaveConversion || 0
                    ),
                  }).map(([label, value], index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                        padding: "2px",
                      }}
                    >
                      <td
                        className="w-48"
                        style={{ padding: "8px", border: "1px solid #ddd" }}
                      >
                        {label}
                      </td>
                      <td
                        className="w-60 text-right"
                        style={{ padding: "8px", border: "1px solid #ddd" }}
                      >
                        {value}
                      </td>
                    </tr>
                  ))}
                  <tr className="font-bold">
                    <td
                      className="w-48"
                      style={{ padding: "8px", border: "1px solid #ddd" }}
                    >
                      Total Gross Pay
                    </td>
                    <td
                      className="w-60 text-right"
                      style={{ padding: "8px", border: "1px solid #ddd" }}
                    >
                      Php {converter(grossPay)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <div className="font-bold">Deductions</div>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <tbody>
                  {Object.entries({
                    SSS: converter(paySlipData?.SSS),
                    Philhealth: converter(paySlipData?.Philhealth),
                    PagIbig: converter(paySlipData?.HDMF),
                    HMO: converter(paySlipData?.maxicare),
                    "PagIbig 2": converter(paySlipData?.hdmf2),
                    "SSS Loan": converter(paySlipData?.sssloan),
                    "PagIbig Loan": converter(paySlipData?.hdmfloan),
                    Advances: converter(paySlipData?.advances),
                    "Other Deductions": converter(paySlipData?.otherDeductions),
                    "Withholding Tax": converter(paySlipData?.withholdingTax),
                  }).map(([label, value], index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                        padding: "2px",
                      }}
                    >
                      <td
                        className="w-48"
                        style={{ padding: "8px", border: "1px solid #ddd" }}
                      >
                        {label}
                      </td>
                      <td
                        className="w-60 text-right"
                        style={{ padding: "8px", border: "1px solid #ddd" }}
                      >
                        {value}
                      </td>
                    </tr>
                  ))}
                  <tr className="font-bold">
                    <td
                      className="w-48"
                      style={{ padding: "8px", border: "1px solid #ddd" }}
                    >
                      Total Deductions
                    </td>
                    <td
                      className="w-60 text-right"
                      style={{ padding: "8px", border: "1px solid #ddd" }}
                    >
                      Php {converter(deductions)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-start">
            <div className="font-bold">Net Pay: Php {converter(netPay)}</div>
          </div>
          {/* <Button label="Print" onClick={handlePrint} /> */}
        </div>
      ) : (
        "Nothing Found"
      )}
    </div>
  );
}

export default Compensation;
