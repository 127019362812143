import React, { useState } from "react";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

export default function EmployeeMovement() {
  const currentUser = useSelector((state) => state.userObject);
  const { id } = useParams();
  const [addMode, setAddMode] = useState(false);
  const [formData, setFormData] = useState({});
  const [assignedId, setAssignedId] = useState(v4());
  

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  const typesOptions = [
    "Promotion",
    "Demotion",
    "Transfer",
    "Resignation",
    "Termination",
    "Retirement",
    "Dismissal",
    "Others",
  ].map((type) => ({ value: type, label: type }));

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      status: "pending",
      createdAt: new Date().toLocaleString(),
      createdBy: currentUser.email,
      user: currentUser.id,
      update: `Created movement for employee ${id}`,
      movementId: assignedId,
    };
    setFormData(data);
  };

  return (
    <div>
      <div className="header">Employee Movement</div>
      <button className="blue-button" onClick={handleAddMode}>
        {addMode ? "Cancel" : "Add"}
      </button>
{/* 
      <div>{JSON.stringify(formData)}</div> */}

      {addMode && (
        <div className="mt-4 p-2 rounded-lg border border-gray-200 shadow-sm bg-white space-y-2">
          <form action="" onSubmit={handleSubmit}>
            <div className="w-60">
              <SelectBox
                name="type"
                options={typesOptions}
                label={"Type of Movement"}
                handleSelect={(e) =>
                  setFormData({ ...formData, type: e.value })
                }
              />
            </div>
            <InputBox
              label="Date of Movement"
              type="date"
              name="effectiveDate"
              placeholder={"Effectivity Date"}
              onChange={handleChange}
            />
            <InputBox
              label="Reason"
              type="text"
              name="reason"
              placeholder={"Reason"}
              onChange={handleChange}
            />
            <InputBox
              label="New Monthly Salary"
              type="number"
              name="newSalary"
              placeholder={"New Salary"}
              onChange={handleChange}
            />
            <button type="submit" className="blue-button mt-4">
              Submit for Approval
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
