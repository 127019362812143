import React, { useState } from "react";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import { addNewDoc } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import { create } from "@mui/material/styles/createTransitions";
import toast from "react-hot-toast";

export default function NewLeaveForm({ setAddMode }) {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [approver, setApprover] = useState("");
  const [leaveType, setLeaveType] = useState("");

  const currentUser = useSelector((state) => state.userObject);

  const typesOfLeaves = [
    "Sick Leave",
    "Vacation Leave",
    "Maternity Leave",
    "Paternity Leave",
    "Bereavement Leave",
    "Unpaid Leave",
  ];
  const leavesOptions = typesOfLeaves.map((leave, index) => {
    return {
      value: leave,
      label: leave,
    };
  });

  const approvers = [
    {
      label: "Henry Cabrera",
      value: "henry.cabrera@leechiu.com",
    },
    {
      label: "Lylah Ledonio",
      value: "lylah.ledonio@leechiu.com",
    },
    {
      label: "Phillip Anonuevo",
      value: "phillip.annonuevo@leechiu.com",
    },
    {
      label: "Roy Golez",
      value: "roy.golez@leechiu.com",
    },
    {
      label: "Alfred Lay",
      value: "alfred.lay@leechiu.com",
    },
    {
      label: "Philis Cruz",
      value: "philis.cruz@leechiu.com",
    },
    {
      label: "David Leechiu",
      value: "david@leechiu.com",
    },
  ];

  const approverOption = [
    {
      label: currentUser.leader,
      value: currentUser.leader,
    },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectApprover = (selected) => {
    setApprover(selected.value);
  };

  const handleSelectLeaveType = (selected) => {
    setLeaveType(selected.value);
  };

  const approverName = approver.split("@")[0];
  const approverFirstName = approverName.split(".")[0];
  const approverLastName = approverName.split(".")[1];

  const handleSendNotification = async () => {
    const docObject = {
      requestor: currentUser.email,
      recipientEmail: approver,
      recipientName: approverFirstName,
      link: "https://myhr.leechiu.app/approverInbox",
      subject: "Leave Request Notification",
      message: `${currentUser.displayName} is requesting for a Leave Request from ${formData.startDate} to ${formData.endDate}. Reason: ${formData.reason}. Please check your myHR for more details.`,
    };

    const sendEmail = async () => {
      setIsProcessing(true);
      await fetch("https://hrms-emailerv1.vercel.app/api/notifications", {
        method: "POST",
        body: JSON.stringify(docObject),
      });

      setIsProcessing(false);
    };
    sendEmail();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check leave type is not blank
    if (leaveType === "") {
      toast.error("Please select Leave Type");
      return;
    }

    const docObject = {
      ...formData,
      name: currentUser.displayName,
      approver: approver,
      approverName: approverFirstName + " " + approverLastName,
      leaveType: leaveType,
      requestedBy: currentUser.email,
      status: "Pending",
      createdAt: new Date().toLocaleString(),
    };

    setIsProcessing(true);
    await addNewDoc("leaveRequests", docObject);

    setIsProcessing(false);
    setAddMode(false);
    toast.success("Leave Request Submitted");
    handleSendNotification();
  };

  return (
    <div className="pt-8 relative small-form space-y-2">
      <button className="close-button" onClick={() => setAddMode(false)}>
        Close
      </button>
      <div className="header">Leave Form</div>

      <div>
        <form action="" onSubmit={handleSubmit} className="space-y-2">
          <SelectBox
            name="approver"
            label={"Approver"}
            placeholder={"Select Approver"}
            options={approverOption}
            handleSelect={(selected) => handleSelectApprover(selected)}
            disabled={isProcessing}
            required
          />
          <SelectBox
            name="leaveType"
            label={"Leave Type"}
            placeholder={"Select Leave Type"}
            options={leavesOptions}
            handleSelect={(selected) => handleSelectLeaveType(selected)}
            disabled={isProcessing}
          />

          <InputBox
            name="numberOfDays"
            type="number"
            label="Number of Working Days"
            placeholder="Number of Working Days"
            onChange={handleChange}
            disabled={isProcessing}
          />

          <InputBox
            name="startDate"
            type="date"
            label="Start Date"
            placeholder="Start Date"
            onChange={handleChange}
            disabled={isProcessing}
          />

          <InputBox
            name="endDate"
            type="date"
            label="End Date"
            placeholder="End Date"
            onChange={handleChange}
            disabled={isProcessing}
          />

          <InputBox
            name="reason"
            type="text"
            label="Reason"
            placeholder="Reason for Leave"
            onChange={handleChange}
            disabled={isProcessing}
          />

          <div className="w-full">
            <button className="blue-button w-full" disabled={isProcessing}>
              {isProcessing ? "Processing..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
